import { countTotalFixedFee, countTimeAndMaterials } from '../../utils/utils'

export const financeFixedFeeRows = (fixedFees) => {
  return fixedFees.map((fee, index) => (
    <tr key={index}>
      <td>{fee.client_name}</td>
      <td>{`${fee.project_name}`}</td>
      <td>{fee.fixed_fee.toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})}</td>
    </tr>
  ))
}

export const financeFixedFeeTotal = (fixedFees) => {
  return (
    <tr>
      <td><h4>Total fixed revenue</h4></td>
      <td><h4>{fixedFees.length}</h4></td>
      <td>
        <h4>
          {countTotalFixedFee(fixedFees).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})}
        </h4>
      </td>
    </tr>
  )
}

export const financeTimeAndMaterialsRows = (timeAndMaterials) => {
  return timeAndMaterials.map((fee, index) => (
    <tr key={index}>
      <td>{fee.client_name}</td>
      <td>{`${fee.project_name}`}</td>
      <td>{(Math.round(fee.billable_amount * 100) / 100).toFixed(2)}</td>
    </tr>
  ))
}

export const financeTimeAndMaterialsTotal = (timeAndMaterials) => {
  return (
    <tr>
      <td><h4>Total t&m revenue</h4></td>
      <td><h4>{timeAndMaterials.length}</h4></td>
      <td>
        <h4>
          {countTimeAndMaterials(timeAndMaterials).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})}
        </h4>
      </td>
    </tr>
  )
}

export const financeTableHeader = () => {
  return (
    <thead>
      <tr>
        <th scope='col'>Client</th>
        <th scope='col'>Assignment</th>
        <th scope='col'>Total</th>
      </tr>
    </thead>
  )
}
