import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Route} from 'react-router-dom'
import {useAuth0} from '../react-auth0-spa'

const PrivateRoute = ({component: Component, path, ...rest}) => {
  const {loading, isAuthenticated, loginWithRedirect, token} = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname}
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  let render

  if (!isAuthenticated) {
    render = loginWithRedirect({})
  } else if (isAuthenticated && !token) {
    render = () => null
  } else {
    // eslint-disable-next-line react/display-name
    render = props =>
      <Component {...props} />
  }

  return <Route path={path} render={render} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.object,
  path: PropTypes.string,
}

export default PrivateRoute


