const getUserBalances = async (token) => {
  return fetch('/api/userBalances', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'GET'
  })
}

const updateData = async (token) => {
  return fetch('/api/updateBalances', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'POST',
  })
}

const getReportData = async (token, month, year) => {
  return fetch(`/api/reportData/${month}&${year}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'GET',
  })
}

const sendReport = async (token, month, year) => {
  return fetch(`/api/report/${month}&${year}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'POST',
  })
}

const getFinanceData = async (token, offset) => {
  return fetch(`/api/financeData/${offset}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'GET',
  })
}


const getInvoiceData = async (token, offset) => {
  return fetch(`/api/invoiceData/${offset}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'GET',
  })
}

const getClients = async (token) => {
  return fetch('/api/getClients', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    method: 'GET'
  })
}

const generateInvoiceReport = async (token, invoiceData) => {
  console.log('Invoice data: ', invoiceData)
  return fetch('/api/generateInvoiceReport', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(invoiceData),
    method: 'POST',
  })
}

export {getUserBalances, updateData, getReportData, sendReport, getFinanceData, getInvoiceData, generateInvoiceReport, getClients}
