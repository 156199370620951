import {useEffect, useState} from 'react'
import {isNil, isEmpty} from 'lodash'
import {useAuth0} from '../../react-auth0-spa'
import {getFinanceData} from '../../api'
import {FinanceTable} from './FinanceTable'
import {countMonthRevenue} from '../../utils/utils'

export const Finance = () => {
  const [financeData, setFinanceData] = useState([])
  const auth = useAuth0()
  const offsetThisMont = 0

  useEffect(() => {
    const fetchData = async () => {
      await getFinanceData(auth.token, offsetThisMont).then((response) => {
        return response.json()
      }).then((response) => {
        setFinanceData(response)
      }).catch((err) => {
        console.log(err)
      })
    }
    fetchData()
  }, [])

  return !isNil(financeData) && !isEmpty(financeData) ? (
    <>
      <h3>{countMonthRevenue(financeData)}</h3>
      <FinanceTable financeData={financeData}/>
    </>
  ) : (
    <div>
      <p>Loading...</p>
    </div>
  )
}
