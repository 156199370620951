import {useEffect, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {useAuth0} from '../../react-auth0-spa'
import {getReportData, sendReport} from '../../api'
import {ReportsTable} from './ReportsTable'
const moment = require('moment')

const PayrollReport = () => {
  const [reportData, setReportData] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [month, setMonth] = useState(moment())
  const auth = useAuth0()
  const initialYear =  moment().month() === 0 ? moment().year() - 1 : moment().year()
  const initialMonth = moment().subtract(1, 'month').format('MM')

  useEffect(() => {
    setMonth(moment().subtract(1, 'month'))
    fetchData(initialMonth, initialYear, false)
  },[])

  const fetchData = async (month, year, reset) => {
    await getReportData(auth.token, month, year).then((response) => {
      return response.json()
    }).then((response) => {
      setReportData(response)
      if (reset) {
        setMonth(moment().subtract(1, 'month'))
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const prevMonth = () => {
    setMonth(month.subtract(1, 'month'))
    const monthToReport = month.format('MM')
    const yearToReport = month.format('YYYY')
    fetchData(monthToReport, yearToReport, false)
  }

  const nextMonth = () => {
    setMonth(month.add(1, 'month'))
    const monthToReport = month.format('MM')
    const yearToReport = month.format('YYYY')
    fetchData(monthToReport, yearToReport, false)
  }

  const emailSentMsg = () => toast('Email has been sent')
  const emailErrorMsg = () => toast.error('Something went wrong. Please contact admin.')

  const handleClick = async () => {
    try {
      setDisabled(true)
      const response = await sendReport(auth.token, month.clone().format('MM'), month.clone().format('YYYY'))
      if(response.status === 200) {
        emailSentMsg()
        setDisabled(false)
      } else {
        emailErrorMsg()
        setDisabled(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className='row pt-3 pt-md-0'>
        <div className='col-7 col-sm-8 col-md-9 col-lg-10'>
          {month.format('MMMM YYYY') !== moment().subtract(1, 'month').format('MMMM YYYY')
            ? <button onClick={() => fetchData(initialMonth, initialYear, true)} className='btn btn-right'>Latest Month</button>
            : null
          }
        </div>
        <div className='col-5 col-sm-4 col-md-3 col-lg-2'>
          <button onClick={handleClick} className='btn btn-right' disabled={disabled}>Send Reports</button>
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
          />
        </div>
      </div>
      <div className='monthPicker row'>
        <div className='arrowLeft col-3 col-md-4'>
          {month < moment().set({'year': 2018, 'month': 8})
            ? null
            : <button className='btn leftBtn p-0'><FontAwesomeIcon onClick={prevMonth} icon={faChevronLeft}/></button>
          }
        </div>
        <div className='monthAndYear col-6 col-md-4'>{month.format('MMMM YYYY')}</div>
        <div className='arrowRight col-3 col-md-4'>
          {month > moment().subtract(2, 'month')
            ? null
            : <button className='btn rightBtn p-0'><FontAwesomeIcon onClick={nextMonth} icon={faChevronRight}/></button>
          }
        </div>
      </div>
      <ReportsTable reportData={reportData} />
    </div>
  )
}

export default PayrollReport
