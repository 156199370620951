import NavBar from './NavBar'
import Footer from './Footer'

const Loading = () => {
  return <div>
    <header>
      <NavBar/>
    </header>
    <div className='container'>
      <p>Loading...</p>
    </div>
    <Footer/>
  </div>
}
export default Loading
