import {FaCheck, FaTrash} from 'react-icons/fa'

export const AddFixedInvoiceRow = (rows, setRows, setAddRow, clients) => {
  const newRow = {}
  return (
    <tr>
      <td>{ SelectField(clients, newRow) }</td>
      <td>{ InputField('text', '', (val) => newRow.project_name = val, 'Assignment') }</td>
      <td>{ InputField('text', '', (val) => newRow.fixed_description = val, 'Description') }</td>
      <td>{ InputField('text', '', (val) => newRow.amount = val, '1') }</td>
      <td>{ InputField('text', '', (val) => newRow.fixed_fee = parseFloat(val), '0.00') }</td>
      <td></td>
      <td className='icon-row'>
        <div className='icon-container'><FaCheck onClick={() => confirmNewRow(setAddRow, newRow, rows, setRows)}/></div>
        <div className='icon-container'><FaTrash onClick={() => setAddRow(false)}/></div>
      </td>
    </tr>
  )
}

export const AddTimeAndMaterialsInvoiceRow = (rows, setRows, setAddRow, clients) => {
  const newRow = {}

  return (
    <tr>
      <td>{ SelectField(clients, newRow) }</td>

      <td>{ InputField('text', '', (val) => newRow.project_name = val, 'Assignment') }</td>
      <td>{ InputField('text', '', (val) => newRow.fixed_description = val, 'Description') }</td>
      <td>{ InputField('number', '', (val) => newRow.hours = parseFloat(val), '0.0') }</td>
      <td>{ InputField('number', '', (val) => newRow.billable_rate = parseFloat(val), '0.00') }</td>
      <td></td>
      <td className='icon-row'>
        <div className='icon-container'><FaCheck onClick={() => confirmNewRow(setAddRow, newRow, rows, setRows)}/></div>
        <div className='icon-container'><FaTrash onClick={() => setAddRow(false)}/></div>
      </td>
    </tr>
  )
}


export const SelectField = (options, row) => {
  row.business_id = row.business_id == undefined ? '' : row.business_id

  const handleChange = (e) => {
    const selected = options.find(element => element.value == e.target.value)
    console.log(selected)
    row.client_name = selected.label
    row.business_id = selected.value
  }

  return (
    <select onChange={handleChange}>
      {options.map(option => {
        return (
          <option key={option.value} value={option.value} onChange={handleChange} selected={option.value == row.business_id ? 'selected' : ''}> {option.label} </option>
        )
      })}
    </select>
  )
}

const confirmNewRow = (setAddRow, newRow, rows, setRows) => {
  setAddRow(false)
  rows.push(newRow)
  setRows(rows)
}

export const InputField = (type, value, setValue, placeHolder) => {
  return <input placeholder={placeHolder ? placeHolder : ''} type = {type} defaultValue={value} onChange = {e => setValue(e.target.value)}/>
}
