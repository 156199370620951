import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import './fonts/Riposte-Regular.otf'
import './fonts/Riposte-Bold.otf'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {Auth0Provider} from './react-auth0-spa'
import history from './utils/history'

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirect_uri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    onRedirectCallback={onRedirectCallback}
  >
    <App/>
  </Auth0Provider>,
  document.getElementById('root')
)
