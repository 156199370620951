import {useState} from 'react'
import {useAuth0} from '../../react-auth0-spa'
import {generateInvoiceReport} from '../../api'
import {
  invoiceTimeAndMaterialsRows,
  invoiceTimeAndMaterialsTotal,
  invoiceFixedFeeRows,
  invoiceFixedFeeTotal,
  invoiceTableHeader
} from './invoiceUtils'

export const InvoicingTable = ({invoiceData, clients}) => {
  const auth = useAuth0()
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [addFixedRow, setAddFixedRow] = useState(false)
  const [addTMRow, setAddTMRow] = useState(false)
  const [fixedFees, setFixedFees] = useState(invoiceData.fixedFees)
  const [timeAndMaterials, setTimeAndMaterials] = useState(invoiceData.timeAndMaterialsWithProjectData)

  fixedFees.sort((a, b) => a.business_id.localeCompare(b.business_id))
  timeAndMaterials.sort((a, b) => a.business_id.localeCompare(b.business_id))

  const sendInvoices = async () => {
    const res = await generateInvoiceReport(auth.token, invoiceData)
      .catch(error => {
        console.log(error)
        setShowError(true)
        window.scrollTo({ top: 0})
        setTimeout(() => {
          setShowError(false)
        }, 5000)
      })
    if (res.status === 200) {
      setShowSuccess(true)
      window.scrollTo({ top: 0})
      setTimeout(() => {
        setShowSuccess(false)
      }, 5000)
    }
  }

  return (
    <>
      {showSuccess ? <h3>Invoices sent successfully!</h3> : ''}
      {showError ? <h3>There was an error sending invoices</h3> : ''}
      <div className='table-responsive'>
        <table className='table table-hover'>
          {invoiceTableHeader(false, setAddTMRow)}
          <tbody>
            {invoiceTimeAndMaterialsRows(timeAndMaterials, setTimeAndMaterials, addTMRow, setAddTMRow, clients)}
            {invoiceTimeAndMaterialsTotal(timeAndMaterials)}
          </tbody>
        </table>
        <table className='table table-hover'>
          {invoiceTableHeader(true, setAddFixedRow)}
          <tbody>
            {invoiceFixedFeeRows(fixedFees, setFixedFees, addFixedRow, setAddFixedRow, clients)}
            {invoiceFixedFeeTotal(fixedFees)}
          </tbody>
        </table>
      </div>
      <button className='btn-update' onClick={sendInvoices}>Send Invoices</button>
    </>
  )
}
