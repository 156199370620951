const Footer = () => {
  return (
    <>
      <footer className='footer border-top'>
        <div>
          <div className='container'>
            <p>Orangit 2021</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
