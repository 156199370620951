import {useEffect, useState} from 'react'
import {isNil, isEmpty} from 'lodash'
import {useAuth0} from '../../react-auth0-spa'
import {getInvoiceData, getClients} from '../../api'
import {InvoicingTable} from './InvoicingTable'

export const Invoice = () => {
  const auth = useAuth0()
  const [invoiceData, setInvoiceData] = useState([])
  const [clients, setClients] = useState([])
  const offsetLastMonth = 1


  useEffect( () => {
    const fetchData = async () => {
      await getInvoiceData(auth.token, offsetLastMonth).then((response) => {
        return response.json()
      }).then((response) => {
        setInvoiceData(response)
      }).catch((err) => {
        console.log(err)
      })
    }
    fetchData()
  }, [])

  useEffect( () => {
    const fetchData = async () => {
      await getClients(auth.token).then((response) => {
        return response.json()
      }).then((response) => {
        setClients(response)
      }).catch((err) => {
        console.log(err)
      })
    }
    fetchData()
  }, [])

  return !isNil(invoiceData) && !isEmpty(invoiceData) ? (
    <InvoicingTable invoiceData={invoiceData} clients={clients} />
  ) : (
    <div>
      <p>Loading...</p>
    </div>
  )
}
