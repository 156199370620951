import {useEffect, useState} from 'react'
import {isNil, isEmpty} from 'lodash'
import {getUserBalances, updateData} from '../api'
import {useAuth0} from '../react-auth0-spa'
import PayrollReport from './reports/PayrollReport'
import moment from 'moment-business-days'

export const Balances = () => {
  const auth = useAuth0()
  const roles = auth.user['https://saldot.orangit/harvestsaldo/roles']
  const isAdmin = roles.includes('admin')
  const isPayroll = roles.includes('payroll')
  const [harvestData, setHarvestData] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [dataLastUpdated, setDataLastUpdated] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      await getUserBalances(auth.token).then((response) => {
        return response.json()
      }).then((response) => {
        setHarvestData(response)
      }).catch((err) => {
        console.log(err)
      })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (harvestData.length > 0) {
      const user = harvestData[0]
      const dataLastUpdated = user.updated_at
      const lastUpdateDate = moment(dataLastUpdated).format('DD.MM.YYYY, HH:mm')
      setDataLastUpdated(lastUpdateDate)
    }
  }, [harvestData])

  const getRowsData = () => {
    return harvestData.map((data) => {
      return (
        <tr key={data.user_id}>
          <td>{data.name}</td>
          <td>{data.current_month_balance}</td>
          <td>{data.all_time_balance}</td>
          <td>{data.absences}</td>
          <td>{data.annual_leave}</td>
        </tr>
      )
    })
  }

  const handleClick = async () => {
    try {
      setDisabled(true)
      await updateData(auth.token)
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  return isPayroll && !isAdmin ? <PayrollReport/>
    : !isNil(harvestData) && !isEmpty(harvestData) ? (
      <>
        <div className='pb-2 row'>
          <div className='col-lg-10 dataUpdated'>
            <p>Data Last Updated: {dataLastUpdated}</p>
          </div>
          {isAdmin &&
          <div className='col-lg-2'>
            <button onClick={handleClick} className='btn btn-right' disabled={disabled}>Update</button>
          </div>
          }
        </div>
        <table className='table table-responsive-sm table-hover'>
          <thead>
            <tr>
              <th scope='col'>Name</th>
              <th scope='col'>Balance This Month (h)</th>
              <th scope='col'>Balance Total (h)</th>
              <th scope='col'>Absences (h)</th>
              <th scope='col'>Remaining Annual Leave (d)</th>
            </tr>
          </thead>
          <tbody>
            {getRowsData()}
          </tbody>
        </table>
      </>
    ) : (
      <div>
        <p>Loading...</p>
      </div>
    )
}

export default Balances
