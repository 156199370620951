export const countTotalFixedFee = (fees) => {
  return fees.length === 0 ? 0 : fees.map((fee) => (fee.fixed_fee))
    .reduce((total, amount) => total + amount)
}

export const countTimeAndMaterials = (fees) => {
  if (fees.length === 0) {
    return 0
  }
  return fees.map((fee) => (parseFloat(fee.billable_rate) * parseFloat(fee.hours)))
    .reduce((total, amount) => total + amount)
}

export const countMonthRevenue = (financeData) => {
  const fixedSum = financeData.fixedFees.map((fee) => (fee.fixed_fee))
  const hoursSum = financeData.timeAndMaterialsWithProjectData.map((fee) => (parseFloat(fee.billable_rate) * parseFloat(fee.hours)))
  const totalSum = fixedSum.length === 0 ? 0 : fixedSum.concat(hoursSum).reduce((total, amount) => total + amount)
    .toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})
  const currentMonth = getCurrentMonth()
  return `${currentMonth[0].toUpperCase()}${currentMonth.slice(1)} revenue: ${totalSum}`
}

export const getCurrentMonth = (locale) => {
  return new Date().toLocaleString(locale ? locale : 'en', {month: 'long'})
}
