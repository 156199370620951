import {ReportsRow} from './ReportsRow'

export const ReportsTable = ({reportData}) => {
  return (
    <>
      <table className='table table-responsive-xl table-hover'>
        <thead>
          <tr>
            <th scope='col'>Name</th>
            <th scope='col'>Work Hours</th>
            <th scope='col'>Hours Total</th>
            <th colSpan='11' scope='col' id='absencesHeader'>Absences (h)</th>
          </tr>
        </thead>
        <thead id='absencesTypes'>
          <tr>
            <th scope='col'></th>
            <th scope='col'></th>
            <th scope='col'></th>
            <th scope='col'>Paid leave</th>
            <th scope='col'>Doctor appointment</th>
            <th scope='col'>Sick leave</th>
            <th scope='col'>Sick child</th>
            <th scope='col'>Maternity leave</th>
            <th scope='col'>Paternity leave</th>
            <th scope='col'>Unpaid leave</th>
            <th scope='col'>Annual leave</th>
            <th scope='col'>Public holiday</th>
            <th scope='col'>Layoff</th>
            <th scope='col'>Flexi leave</th>
          </tr>
        </thead>
        <tbody>
          {reportData.map((data) => {
            return <ReportsRow key={data.name} data={data} />
          })}
        </tbody>
      </table>
    </>
  )
}
