import {useAuth0} from '../react-auth0-spa'
import logo from '../images/orangit_navbar.png'
import React from 'react'

const NavBar = () => {
  const {isAuthenticated, loading, logout} = useAuth0()
  const location = window.location.pathname
  const auth = useAuth0()
  let isAdmin
  let isFinance
  let isPayroll
  let isHarvestId
  if (isAuthenticated && auth.user !== undefined) {
    isHarvestId = !!auth.user['https://saldot.orangit/harvestsaldo/user_metadata']['harvest_id']
    const roles = auth.user['https://saldot.orangit/harvestsaldo/roles']
    isAdmin = roles.includes('admin')
    isFinance = roles.includes('finance')
    isPayroll = roles.includes('payroll')
  }

  return (
    <div className='container'>
      {loading && (<nav className='navbar navbar-light'>
        <div className='navbar-main'>
          <div className='navbar-nav mr-auto'>
            <a className='navbar-brand' href='/'>
              <img src={logo}
                id='navLogo'
                alt='Orangit-logo'/>
            </a>
          </div>
        </div>
      </nav>
      )}
      {isAuthenticated && (
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="navbar-nav">
            <a className='navbar-brand' href='/'>
              <img src={logo} id='navLogo' alt='Orangit-logo'/>
            </a>
            {(isHarvestId || isAdmin ? (
              <a className={location === '/' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/">
                Balances
              </a>
            ): null)}
            {isAdmin ? (
              <>
                <a className={location === '/reports' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/reports">
              Reports
                </a>
                <a className={location === '/finance' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/finance">
              Finances
                </a>
                <a className={location === '/invoicing' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/invoicing">
              Invoicing
                </a>
              </>
            ) : isFinance ? (
              <>
                <a className={location === '/finance' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/finance">
              Finances
                </a>
                <a className={location === '/invoicing' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/invoicing">
                  Invoicing
                </a>
              </>
            ) :  isPayroll ? (
              <a className={location === '/reports' ? 'nav-item nav-link active' : 'nav-item nav-link'} href="/reports">
                Reports
              </a>
            ) : null }
            <button className='btn btn-nav' onClick={() => logout()}>Log out</button>
          </div>
        </nav>
      )}
    </div>
  )
}

export default NavBar
