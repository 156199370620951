import {financeFixedFeeRows, financeFixedFeeTotal, financeTableHeader, financeTimeAndMaterialsRows, financeTimeAndMaterialsTotal} from './financeUtils'

export const FinanceTable = ({financeData}) => {
  const fixedFees = financeData.fixedFees
  const timeAndMaterials = financeData.timeAndMaterialsWithProjectData

  return (
    <>
      <div className='table-responsive'>
        <hr />
        <h4>Time & materials</h4>
        <table className='table table-hover'>
          {financeTableHeader(false)}
          <tbody>
            {financeTimeAndMaterialsRows(timeAndMaterials)}
            {financeTimeAndMaterialsTotal(timeAndMaterials)}
          </tbody>
        </table>
      </div>
      <div className='table-responsive'>
        <hr />
        <h4>Fixed fees</h4>
        <table className='table table-hover'>
          {financeTableHeader(true)}
          <tbody>
            {financeFixedFeeRows(fixedFees)}
            {financeFixedFeeTotal(fixedFees)}
          </tbody>
        </table>
      </div>
    </>
  )
}
