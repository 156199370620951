import React from 'react'
import './App.css'
import NavBar from './components/NavBar'
import {Router, Switch} from 'react-router-dom'
import { Finance } from './components/finance/Finance'
import PayrollReport from './components/reports/PayrollReport'
import { Invoice } from './components/invoicing/Invoice'
import history from './utils/history'
import PrivateRoute from './components/PrivateRoute'
import Footer from './components/Footer'
import {useAuth0} from './react-auth0-spa'
import Loading from './components/Loading'
import {Redirect} from 'react-router-dom'
import {Balances} from './components/Balances'

const App = () => {
  const {loading} = useAuth0()
  return (
    <div className='App' id='page-container'>
      {loading
        ? <Loading/>
        : <Router history={history}>
          <header>
            <NavBar/>
          </header>
          <div id='content-wrap' className='container'>
            <Switch>
              <PrivateRoute exact path='/' component={Balances} />
              <PrivateRoute path='/reports' component={PayrollReport} />
              <PrivateRoute path='/finance' component={Finance} />
              <PrivateRoute path='/invoicing' component={Invoice} />
              <Redirect exact from='/:id' to='/' />
            </Switch>
          </div>
          <Footer/>
        </Router>}
    </div>
  )
}

export default App
