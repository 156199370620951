import { countTimeAndMaterials, countTotalFixedFee } from '../../utils/utils'
import {FaEdit, FaTrash, FaCheck, FaPlusSquare } from 'react-icons/fa'
import { confirm } from 'react-confirm-box'
import {useState} from 'react'
import {AddFixedInvoiceRow, AddTimeAndMaterialsInvoiceRow, InputField, SelectField} from './AddInvoiceRow'

export const invoiceTimeAndMaterialsRows = (timeAndMaterials, setTimeAndMaterials, addRow, setAddRow, clients) => {
  const [editRow, setEditRow] = useState([])
  return (
    <>
      { addRow ? AddTimeAndMaterialsInvoiceRow(timeAndMaterials, setTimeAndMaterials, setAddRow, clients, null) : null }
      { timeAndMaterials.length !== 0 ? getTimeAndMaterialsRows(timeAndMaterials, setTimeAndMaterials, setEditRow, editRow, clients) : null }
    </>
  )

}

const getTimeAndMaterialsRows = (rows, setRows, setEditRow, editRow, clients) => {
  return rows.map((fee, index) => {
    const isEditable = isRowEditable(editRow, index)
    return (
      <tr key={index}>
        {isEditable ? <>

          <td>{ SelectField(clients, fee) }</td>
          <td>{ InputField('text', fee.project_name, (val) => fee.project_name = val) }</td>
          <td>{ InputField('text', fee.description, (val) => fee.description = val) }</td>
          <td>{ InputField('text', fee.hours, (val) => fee.hours = parseFloat(val)) }</td>
          <td>{ InputField('text', fee.billable_rate, (val) => fee.billable_rate = parseFloat(val)) }</td>
          <td>{ fee.billable_rate ? (fee.billable_rate * parseFloat(fee.hours)).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'}) : 0 }</td>
        </> : <>
          <td>{ fee.client_name }</td>
          <td>{ fee.project_name }</td>
          <td>{ fee.description }</td>
          <td>{ (Math.round(fee.hours * 100) / 100).toFixed(2) }</td>
          <td>{ fee.billable_rate ? fee.billable_rate : '!!!' }</td>
          <td>{ fee.billable_rate ? (fee.billable_rate * parseFloat(fee.hours)).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'}) : 0 }</td>
        </>}
        <td className='icon-row'>
          <div className='icon-container'>{ isEditable ? <FaCheck className='icon' onClick={() => setEditRow([index, false])}/> : <FaEdit onClick={() => setEditRow([index, true])}/> }</div>
          <div className='icon-container'><FaTrash className='icon' onClick={() => openConfirm(setRows, setEditRow, rows, fee) }/></div>
        </td>
      </tr>
    )
  })
}

export const invoiceTimeAndMaterialsTotal = (timeAndMaterials) => {
  return (
    <tr>
      <td><h4>Total t&m billable</h4></td>
      <td><h4>{timeAndMaterials.length}</h4></td>
      <td />
      <td />
      <td />
      <td><h4>{countTimeAndMaterials(timeAndMaterials).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})}</h4></td>
      <td />
    </tr>
  )
}

export const invoiceFixedFeeRows = (fixedFees, setFixedFees, addRow, setAddRow, clients) => {
  const [editRow, setEditRow] = useState([])
  return (
    <>
      { addRow ? AddFixedInvoiceRow(fixedFees, setFixedFees, setAddRow, clients) : null }
      { getFixedFeeRows(fixedFees, setFixedFees, editRow, setEditRow, clients) }
    </>
  )
}

const getFixedFeeRows = (rows, setRows, editRow, setEditRow, clients) => {
  return rows.map((fee, index) => {
    const isEditable = isRowEditable(editRow, index)
    return (
      <tr key={index}>
        {isEditable ? <>
          <td>{ SelectField(clients, fee) }</td>
          <td>{ InputField('text', fee.project_name, (val) => fee.project_name = val) }</td>
          <td>{ InputField('text', fee.fixed_description, (val) => fee.fixed_description = val) }</td>
          <td>{ InputField('text', fee.amount, (val) => fee.amount = val) }</td>
          <td>{ InputField('number', fee.fixed_fee, (val) => fee.fixed_fee = parseFloat(val))}</td>
          <td>{ (fee.fixed_fee).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})}</td>
        </> : <>
          <td>{ fee.client_name }</td>
          <td>{ fee.project_name }</td>
          <td>{ fee.fixed_description }</td>
          <td>{ fee.amount == undefined ? 1 : fee.amount }</td>
          <td>{ fee.fixed_fee.toLocaleString('fi-FI', { style: 'currency', currency: 'eur'}) }</td>
          <td>{ (fee.amount == undefined ? fee.fixed_fee : (fee.amount * fee.fixed_fee)).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'}) }</td>
        </>}
        <td className='icon-row'>
          <div className='icon-container'>{ isEditable ? <FaCheck className='icon' onClick={() => setEditRow([index, false])}/> : <FaEdit className='icon' onClick={() => setEditRow([index, true])}/> }</div>
          <div className='icon-container'><FaTrash className='icon' onClick={() => openConfirm(setRows, setEditRow, rows, fee) }/></div>
        </td>
      </tr>
    )
  })
}

const openConfirm = async (setRows, setEditRow, rows, fee) => {
  const options = {
    labels: {
      confirmable: 'Confirm',
      cancellable: 'Cancel'
    }
  }
  window.scrollTo(0, 0)
  const result = await confirm('Delete ' + fee.project_name + ' from list?', options)
  if (result) {
    removeRow(setRows, setEditRow, rows, fee)
  }
}

const removeRow = (setRows, setEditRow, rows, fee) => {
  setEditRow([])
  setRows(rows.filter((item) => {return item !== fee}))
}


const isRowEditable = (editRow, index) => {
  return editRow[0] === index && editRow[1]
}

export const invoiceFixedFeeTotal = (fixedFees) => {
  return (
    <tr>
      <td><h4>Total fixed fee billable</h4></td>
      <td><h4>{fixedFees.length}</h4></td>
      <td />
      <td />
      <td />
      <td><h4>{countTotalFixedFee(fixedFees).toLocaleString('fi-FI', { style: 'currency', currency: 'eur'})}</h4></td>
    </tr>
  )
}

export const invoiceTableHeader = (isFixedFee, setState) => {
  return (
    <thead>
      <tr>
        <th scope='col'>Client</th>
        <th scope='col'>Assignment</th>
        <th scope='col'>Description</th>
        <th scope='col'>{isFixedFee ? 'Amount' : 'Hours'}</th>
        <th scope='col'>{isFixedFee ? '€/item' : '€/h'}</th>
        <th scope='col'>Total</th>
        <th scope='col' className='icon-row'><FaPlusSquare className='icon' onClick={() => { setState(true) }}/></th>
      </tr>
    </thead>
  )
}
