export const ReportsRow = ({data}) => {
  return (
    <tr key={data.name}>
      <td>{data.name}</td>
      <td>{data.workHours}</td>
      <td>{data.totalHours}</td>
      <td>{data.paidLeave}</td>
      <td>{data.doctorAppointment}</td>
      <td>{data.sickLeave}</td>
      <td>{data.sickChild}</td>
      <td>{data.maternityLeave}</td>
      <td>{data.paternityLeave}</td>
      <td>{data.unpaidLeave}</td>
      <td>{data.annualLeave}</td>
      <td>{data.publicHoliday}</td>
      <td>{data.layoff}</td>
      <td>{data.flexiLeave}</td>
    </tr>
  )
}
